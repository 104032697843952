/* Route.css */
.card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.message {
    margin-bottom: 10px; /* Space between the message and button groups */
}

.button-groups-container {
    display: flex;
    flex-direction: row; /* Align button groups horizontally */
    gap: 10px; /* Space between button groups */
}

.button-group-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

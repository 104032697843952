a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    font-family: 'Segoe UI'
}

html {
    font-size: 14px;
}
@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    background-color: cornflowerblue;
    font-family: 'Segoe UI'
}

.inactive {
    color: #fff;
}

.active {
    color: red;
    background-color: white;
}
